<template>
   <main>
      <!-- HEAD -->
      <section class="slider__area pt-30 grey-bg" v-if="config.banner1 != null && config.banner1.trim().length > 0 && config.banner2 != null && config.banner2.trim().length > 0">
         <div class="container">
            <div class="row">
               <div class="col-xl-2 custom-col-2 d-none d-xl-block">
                  <div class="cat__menu-wrapper">
                     <div class="cat-toggle">
                        <button type="button" class="cat-toggle-btn"><i class="fas fa-bars"></i> Categorias</button>
                        <div class="cat__menu">
                           <nav id="mobile-menu">
                              <ul class="overflow">
                                 <li v-for="(categoria, index) in listaCategorias" :key="index">
                                    <a href="javascript:;" class="hover" :title="'Buscar por '+ categoria.categoria" @click="pesquisar(categoria.categoria)">
                                       {{ categoria.categoria }}
                                    </a>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-xl-10 custom-col-10 col-lg-12">
                  <div class="slider__inner slider-active">
                     <div class="single-slider w-img" v-if="config.banner1 != null && config.banner1.trim().length > 0">
                        <img :src="config.banner1" alt="banner1">
                     </div>
                     <div class="single-slider w-img" v-if="config.banner2 != null && config.banner2.trim().length > 0">
                        <img :src="config.banner2" alt="banner2">
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- BANNERS -->
      <section class="features__area grey-bg-2 pt-30 pb-20 pl-10 pr-10" v-if="config.descricao1 != null && config.descricao1.trim().length > 0 && 
         config.descricao2 != null && config.descricao2.trim().length > 0 && config.descricao3 != null && config.descricao3.trim().length > 0">
         <div class="container">
            <div class="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 gx-0">
               <div class="col" v-if="config.descricao1 != null && config.descricao1.trim().length > 0">
                  <div class="features__item d-flex white-bg">
                     <div class="features__icon mr-15">
                        <i class="fal fa-info-circle"></i>
                     </div>
                     <div class="features__content">
                        <p>{{ config.descricao1 }}</p>
                     </div>
                  </div>
               </div>
               <div class="col" v-if="config.descricao2 != null && config.descricao2.trim().length > 0">
                  <div class="features__item d-flex white-bg">
                     <div class="features__icon mr-15">
                        <i class="fal fa-info-circle"></i>
                     </div>
                     <div class="features__content">
                        <p>{{ config.descricao2 }}</p>
                     </div>
                  </div>
               </div>
               <div class="col" v-if="config.descricao3 != null && config.descricao3.trim().length > 0">
                  <div class="features__item d-flex white-bg">
                     <div class="features__icon mr-15">
                        <i class="fal fa-info-circle"></i>
                     </div>
                     <div class="features__content">
                        <p>{{ config.descricao3 }}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- BANNERS -->
      <section class="banner__area pt-20 grey-bg-2 d-none d-lg-block" v-if="config.banner3 != null && config.banner3.trim().length > 0 && config.banner4 != null && config.banner4.trim().length > 0">
         <div class="container">
            <div class="row">
               <div class="col-xl-6 col-lg-6">
                  <div class="banner__item mb-30 w-img">
                     <a href="javascript:;"><img :src="config.banner3" alt="banner3"></a>
                  </div>
               </div>
               <div class="col-xl-6 col-lg-6">
                  <div class="banner__item mb-30 w-img">
                     <a href="javascript:;"><img :src="config.banner4" alt="banner4"></a>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="deal__area grey-bg" v-if="listaProdutos.um.length >= 3 || listaProdutos.dois.length >= 3 || listaProdutos.tres.length >= 3 || listaProdutos.quatro.length >= 3">
         <div class="container">
            <div class="row">
               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1" v-if="listaProdutos.um.length >= 3">{{ listaProdutos.um[0].categoria.toUpperCase() }}</span><span class="ms-1" v-else>-</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.um.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.um" :key="('um'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>

               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1" v-if="listaProdutos.dois.length >= 3">{{ listaProdutos.dois[0].categoria.toUpperCase() }}</span><span class="ms-1" v-else>-</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.dois.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.dois" :key="('dois'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>

               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1" v-if="listaProdutos.tres.length >= 3">{{ listaProdutos.tres[0].categoria.toUpperCase() }}</span><span class="ms-1" v-else>-</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.tres.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.tres" :key="('tres'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>

               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1" v-if="listaProdutos.quatro.length >= 3">{{ listaProdutos.quatro[0].categoria.toUpperCase() }}</span><span class="ms-1" v-else>-</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.quatro.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.quatro" :key="('quatro'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>
            </div>
         </div>
      </section>

      <!-- BANNERS -->
      <section class="banner__area pb-40 grey-bg-2" v-if="config.banner5 != null && config.banner5.trim().length > 0 && config.banner6 != null && config.banner6.trim().length > 0 && 
         config.banner7 != null && config.banner7.trim().length > 0">
         <div class="container">
            <div class="row">
               <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="banner__item mb-30 w-img">
                     <a href="javascript:;"><img :src="config.banner5" alt="banner5"></a>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="banner__item mb-30 w-img">
                     <a href="javascript:;"><img :src="config.banner6" alt="banner6"></a>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="banner__item mb-30 w-img">
                     <a href="javascript:;"><img :src="config.banner7" alt="banner7"></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import oferta from '@/components/home/Produto.vue'

export default {
	name: 'Home',
   computed: {
		... mapState({
			listaCategorias: state => state.listaCategorias,
			listaProdutos: state => state.listaProdutos,
			isRouteAdmin: state => state.isRouteAdmin,
			urlRest: state => state.urlRest,
			config: state => state.config
		})
	},
   components: {
		oferta
	},
   methods: {
      pesquisar : function (categoria) {
         this.$store.dispatch('pesquisar', {'valor': '', 'categoria': categoria, 'subcategoria': 'Todas subcategorias'})
      },
      getData : function () {
			let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
            method: 'get',
            url: this.urlRest +'product/getdata'

         }).then(function (response) {
				ref.$store.dispatch('saveData', response.data)
            
         }).catch(function (error) {
            if (error.response != undefined) {
					ref.$toast.fire({
						icon: 'error',
						title: 'Erro: ' + error.response.status
					});
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)

				if (!this.isRouteAdmin) {
					$(document).ready(function() {
						if (localStorage.theme != null) {
							$("html").removeClass(localStorage.theme)
						}

						$('select').niceSelect('update');
					
						$('.cat-toggle-btn').on('click', function () {
							$('.cat__menu').slideToggle(500);
						});
			
						$('.slider-active').on('init', function () {
							var $firstAnimatingElements = $('.single-slider:first-child').find('[data-animation]');
							ref.doAnimations($firstAnimatingElements)
						});
			
						$('.slider-active').on('beforeChange', function (e, slick, currentSlide, nextSlide) {
							var $animatingElements = $('.single-slider[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
							ref.doAnimations($animatingElements)
						});
			
						$('.slider-active').slick({
							autoplay: true,
							autoplaySpeed: 8000,
							dots: true,
							fade: false,
							arrows: false,
							prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-angle-left"></i></button>',
							nextArrow: '<button type="button" class="slick-next"><i class="fal fa-angle-right"></i></button>',
							responsive: [{
								breakpoint: 767,
								settings: {
									dots: false,
									arrows: false
								}
							}]
						});
			
						if ($('.product__electronic-3').length != 0) {
							$('.product__electronic-3').owlCarousel({
								loop:true,
								margin:20,
								autoplay:false,
								autoplayTimeout:60000,
								smartSpeed:500,
								nav:false,
								dots:false,
								responsive:{
									0:{
										items:1
									},
									576:{
										items:2
									},
									767:{
										items:3
									},
									992:{
										items:3
									},
									1200:{//x2 para full
										items:2
									},
									1600:{//x2 para full
										items:3
									}
								}
							});
						}
					});
				}
         });
		},
      doAnimations : function (elements) {
         var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            
         elements.each(function () {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
               'animation-delay': $animationDelay,
               '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function () {
               $this.removeClass($animationType);
            });
         });
      }
   },
   mounted() {
      this.getData()
   }
}

</script>